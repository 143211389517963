import React from "react";
import FormSection from "../FormSection/FormSection.js";
import ProductTypes from "../ProductTypes/ProductTypes.js";
import "./home.css";
import village1 from "../../images/village.jpg";
import village2 from "../../images/village2.jpg";

const Home = ({setIsFooter}) => {
  setIsFooter(true)
  return (
    <div>
      <FormSection />

      {/* <CarouselSlide/> */}

      {/* <div className="productBox">
        <div className="headingBox"><h1>Soothe & Comfort</h1></div>

        <div className="productItem">
          <div className="productImage">
            <img src={productImage} alt="productImage"  />
          </div>
          <div className="productContain">

            <h2>Support your fitness goals with ColorScheme Deep Blue® Products</h2>
            <p>Fresh oranges are sourced from the villages of Nagpur. Cold-pressing of the rind is done to extract oils. The oils with matchless aroma is then poured into bottles and stored to enhance it's flavor and aroma.</p>
            <Link to={""} ><span>Shop now</span></Link>
          </div>
        </div>
      </div> */}

      <div className="productbox2">
        <div className="productDetail">
          <h1>Why Fragrens?</h1>
          <p>
            In the world of so many distractions, we owe it to our primary
            senses to do justice to our being. Fragrens is crafted and built to
            cater to those primary senses. As a premium brand, we offer the
            entire spectrum of quality oils to meet the diverse needs of our
            customers. Our Carrier Oils, Essential Oils and Fragrance Oils are
            handcrafted to carefully blend as needed and enhance the entire
            experience. Rather, luxury experience that goes beyond aromatherapy.
          </p>
          <span>
            <a target="_black" href="https://www.thefragrens.com">
              {" "}
              Browse Our Products
            </a>{" "}
          </span>
        </div>
        <div className="productScroll">
          {/* <Carousel> */}
          {/* <div className="SlideItem">
              <img src={require('../../images/dropper_bottle_mockup_black_cap_720x.webp')} width='100%' height='90%' alt="" />
              <h3><a rel="noreferrer"  target='_blank' href="https://thefragrens.com/collections/essential-oils/products/winter-shield">Winter-shield</a> </h3>
            </div> */}
          <div className="SlideItem">
            <img
              src={require("../../images/lavbs_720x.webp")}
              width="100%"
              height="90%"
              alt=""
            />
            <h3>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://thefragrens.com/collections/fragrance-oils"
              >
                Fragrance Oils
              </a>{" "}
            </h3>
            {/* https://thefragrens.com/collections/essential-oils-1 */}
          </div>
          <div className="SlideItem">
            <img
              src={require("../../images/Untitled_design_23_720x.webp")}
              height="90%"
              width="100%"
              alt=""
            />
            <h3>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://thefragrens.com/collections/essential-oils-1"
              >
                Essential Oils
              </a>{" "}
            </h3>
          </div>
          <div className="SlideItem">
            <img
              src={require("../../images/coco_1_720x.webp")}
              height="90%"
              width="100%"
              alt=""
            />
            <h3>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://thefragrens.com/collections/carrier-oils"
              >
                Carrier Oils
              </a>{" "}
            </h3>
          </div>

          <div className="Scrollborder">
            <div> </div>
          </div>

          {/* </Carousel> */}
        </div>
      </div>

      <ProductTypes />

     

      <div className="villageBannarContainer">
        <div className="villageBannarBox1">
          <h1>Sourcing only the best.</h1>
          <p>
            How Fragrens sources its product is very important because that is
            all the difference between a good and a mediocre product.
          </p>
        </div>
             {/* Gifting secsion  */}
             <div className="giftContainer">
                {/* <div className="giftBox">
                    <img src={require("../../images/gifting1.jpg")} alt="gift" />
                    <h3>Gift</h3>
                </div> */}
            </div>
      {/* Gifting secsion end */}
        <div className="villageBannarBox">
          <div className="villageItem">
            <div className="imageBox">
              <img src={village1} alt="" />
            </div>
            <div className="contentBox">
              <h3>Direct from the farmer.</h3>
              <p>
                Fragrens is not reliant on middlemen to get the job done. We
                source directly from the farmers from areas that specialize in a
                particular aromatic crop. There are no compromises.
              </p>
              {/* <span>Learn more</span> */}
            </div>
          </div>

          <div className="villageItem">
            <div className="imageBox">
              <img src={village2} alt="" />
            </div>
            <div className="contentBox">
              <h3>Inhouse distillation and manufacturing.</h3>
              <p>
                Based in Rajasthan, Fragrens houses its very own inhouse
                distillation and manufacturing of products that completely
                negates any possibility of impurities, escalating the quality of
                the product manifolds.
              </p>
              {/* <span>Learn more</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
