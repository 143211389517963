import React from 'react'
import './HowToUse.css'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'





const HowToUsePage = ({setIsFooter}) => {
    setIsFooter(true)
    return (
        <div className='howToContainer'>
            <div className="space"></div>
            <h2>HOW TO USE </h2>

            <div className="topContain">
                <p>
                    Too many varieties and types of essential oils can be really confusing right? Don’t worry we are here to help you!
                </p> <br />

                <p>
                    <b>At FRAGRENS, we have three primary category of oils - </b>
                </p>
                <p>1. Essential Oils that are pure and natural in nature.</p>
                <p>2.Fragrance Oils that are synthetically reconstituted to imitate the natural oil’s aroma.</p>
                <p>3. Carrier Oils that are primarily used to carry or dilute essential oils.

                </p>
                <br />

                <p>
                 <b>   Essential Oils can be used in 3 kind of applications as the following-</b>

                </p>

                <p>  <b> 1. Aromatic application:</b> When such oils are diffused in the air to aromatically impact the immediate environment.</p>
                <p>  <b>2. Topical application: </b> When such oils are applied on the surface of the subject or the skin for their direct absorption.
                </p>
                <p>  <b>3. Internal  application:</b> When such oils are consumed directly through the mouth to make the most of their benefits. Please note that not all Essential oils are suitable for
                    internal usage.
                </p>
                <br />
                <p> <b>Not every oil can be used for every kind of application.</b></p>
                <p>
                    <b>*</b> For topical applications, only carrier oils and pure essential oils can be used <br />
                    <b>*</b> For aromatic applications, only pure essential oils, diluted with carrier oil or any other solvent; and fragrance oils can be used. <br />
                    <b>*</b> For internal application, only pure essential oils can be used, in extremely small dosage.
                </p>
                <br />
                <p><b>
                    Summary of above written information
                </b></p>
                <img src={require('../../images/table.png')} alt="" /> <br />
                <p>For topical application, dilute your Fragrens Pure & Natural Essential Oil in Fragrens Carrier Oil, for best results.
                    For aromatic application, you may add a few drops of your Fragrens Fragrance Oil to your diffuser and enjoy the aroma.
                    For internal application, consult an expert. Do not consume without enough safety data.
                </p>
                <br />
                <p><b>CAUTION:</b> Always perform a patch test before topical use. Kindly, refer the video below for conducting a patch test.


                </p> <br />
                <p><b>Below is the detailed use case of each kind of application:
                </b></p>
            </div>

            <div className="topicalAppBox">
                <div className='contentBox'>
                    <h1>Aromatic  application : <br /> It allows the essential oils to get absorbed into the skin.
                    </h1>
                    <br />
                    <p> <b>Dilution</b>:  Pure essential oils are highly concentrated and potent, so it's generally recommended to dilute them before applying them to the skin. Dilution helps reduce the risk of skin irritation or sensitization. The most common dilution ratio is 1-2% essential oil to a carrier oil, such as jojoba oil, coconut oil, or almond oil. For example, for every teaspoon (5 ml) of carrier oil, add 1-2 drops of essential oil.
                    </p> <br />
                    <p> <b>Skin Application</b>:  Once you have diluted the essential oil, you can apply it to the skin. Conduct a patch test on a small area of skin to check for any adverse reactions before applying it to larger areas of the body. Apply the diluted essential oil to the desired area and gently massage it into the skin.
                    </p>
                    <br />
                    <p> <b>Compress</b> : Essential oils can be used in compresses to address specific concerns. Add a few drops of essential oil to a bowl of warm or cold water, soak a clean cloth in the mixture, and apply it to the desired area. Compresses can be used for muscle aches, skin irritations, or to help reduce inflammation.</p>
                </div>
                <div className='imgBox'>
                    <img src={require('../../images/rose.jpeg')} alt="" />

                </div>
            </div>

            <div className="topicalAppBox">
                <div className='imgBox'>
                    <img src={require('../../images/jorja.jpeg')} alt="" />

                </div>
                <div className='contentBox'>
                    <h1>Topical application : <br /> It allows the essential oils to get absorbed into the skin.
                    </h1>
                    <br />
                    <p> <b>Dilution</b>:  Pure essential oils are highly concentrated and potent, so it's generally recommended to dilute them before applying them to the skin. Dilution helps reduce the risk of skin irritation or sensitization. The most common dilution ratio is 1-2% essential oil to a carrier oil, such as jojoba oil, coconut oil, or almond oil. For example, for every teaspoon (5 ml) of carrier oil, add 1-2 drops of essential oil.
                    </p> <br />
                    <p> <b>Skin Application</b>:  Once you have diluted the essential oil, you can apply it to the skin. Conduct a patch test on a small area of skin to check for any adverse reactions before applying it to larger areas of the body. Apply the diluted essential oil to the desired area and gently massage it into the skin.
                    </p>
                    <br />
                    <p> <b>Compress</b> : Essential oils can be used in compresses to address specific concerns. Add a few drops of essential oil to a bowl of warm or cold water, soak a clean cloth in the mixture, and apply it to the desired area. Compresses can be used for muscle aches, skin irritations, or to help reduce inflammation.</p>
                </div>

            </div>

            <div className="topicalAppBox">
                <div className='contentBox'>
                    <h1>Internal   application : <br /> *We recommend expert opinion before internal consumption..
                    </h1>
                    <br />
                    <p> <b>Fragrens pure essential oils can be consumed internally for lots of benefits.</b></p> <br />

                    <p> <b>
                        * Fragrens Frankincense pure essential oil</b> can be consumed to improve the immune system and its function.

                    </p>
                    <br />
                    <p> <b>* Fragrens Peppermint pure essential oil and Fragrance Lavender pure essential oil </b> can be consumed to support the health of your respiratory system and fight allergies.</p>
                    <br />


                    <p>* If your body is exposed to too much smoke and radiation, <b> Fragrens Ylang Ylang pure essential oil </b> is for you to consume so that it can protect your body from free radical damage by preparing antioxidants.</p>
                    <br />

                    <p> <b>* Fragrens Lemongrass Pure essential oil and Fragrens Frankincense pure essential oil </b> can be combinedly taken to improve your digestive system.</p>
                    <br />

                    <p> <b>* Fragrens Lavender pure essential oil </b> can also be taken to calm your nerves and senses and to have a better sleep.
</p>
<br />
<p>* We recommend expert supervision before consuming these oils.
</p>
                </div>
                <div className='imgBox'>
                    <img src={require('../../images/rose.jpeg')} alt="" />

                </div>
            </div>
        </div>
    )
}

export default HowToUsePage