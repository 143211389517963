import React, { useState } from 'react'
// import Rating  from "react-rating";
import Rating from '@mui/material/Rating';
import axios from 'axios';
import './FormSection.css'
import { Sheet_Connection_Url } from '../../productionvariable'
// React.createElement(Rating)





const FormSection = () => {
    const [rating, setRating] = useState(0);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [dob, setDob] = useState("");
    const [product, setProduct] = useState("");
    const [isTrue, setIsTrue] = useState(false);


    const formSubmitHandler = async (e) => {
        e.preventDefault();
        // console.log(name, email, rating, dob)
        const data = {
            Name: name,
            Email: email,
            DOB: dob,
            Rating: rating,
            Product: product
        }

        if (rating === 0) {
            alert("Please give rating.")

        } else {
            document.getElementById("loading").innerHTML = "Proccessing..."
            setIsTrue(true)


            await axios.post(Sheet_Connection_Url, data,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json'
                    }
                }
            ).then((res) => {
                console.log(res)

                setEmail("");
                setDob("");
                setName("");
                setRating(0)
                setProduct("")
                alert("Email Send SuccussFully. Thank-you for Join us")
                window.location.reload()

            }).catch((error) => {
                document.getElementById("loading").innerHTML = ""
                setIsTrue(false)


                const checkDupicate = error.response.data.message.search('duplicate')
                // console.log(checkDupicate)
                if (checkDupicate !== -1) {
                    alert("This Email is Already Exist. Please Enter new Email")
                }

                alert(`Technical Error. ${error.response.data.message}`)
                // console.log(error.response.data.message)
                // console.log(response.message)
                // window.location.reload()

            })
        }

    }
   

    return (
        <>
            <div className="FormSectionContainer">

                <div className="box2">
                    <h2>Love surprises? So do we.
                        Let us surprise you soon.</h2>
                    <form action="" className='' onSubmit={formSubmitHandler}>
                        <input className='inpStyle' type="name" required placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
                        <p className='doblable'>Select date of Birth </p>
                        <input type="date" className='dob' required placeholder='' value={dob} onChange={(e) => setDob(e.target.value)} />
                        <input type="email" className='inpStyle' required placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />

                        <select id="product" value={product} onChange={(e) => setProduct(e.target.value)} className='inpStyle' required name="Product">
                            <option value="">Product you bought</option>
                            <option value="carrier oils">Carrier oils</option>
                            <option value="essential oil">Essential oils</option>
                            <option value="fragrance oil"> Fragrance oils</option>
                            {/* <option value=" fragrance oil"> Purpose Oils</option> */}
                            <option value=" fragrance oil"> Nothing yet</option>
                        </select>

                        <div className="ratingBox">
                            <p>Rating</p>
                            <Rating onChange={(e) => setRating(e.target.value)} value={rating} size="large" />
                            {/* {rating} */}
                        </div>
                        <p id='loading'></p>
                        <input type="submit" className='FormSectionBtn' disabled={isTrue} />

                    </form>
                </div>
                <div className="box1">
                    {/* <img src={require("../../images/Orange_1_540x.webp")} alt="" /> */}
                </div>
            </div>
        </>
    )
}

export default FormSection

