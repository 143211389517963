import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from '../src/Components/Home/Home.js';
import HomeTabs from '../src/Components/HomeTabs/HomeTabs.js';
import HowToUsePage from '../src/Components/HowToUsePage/HowToUsePage.js';
import BenefitsPage from '../src/Components/BenefitsPage/BenefitsPage.js';
import WaitListPage from '../src/Components/WaitListPage/WaitListPage.js';

// import Contect from '../src/Components/Contect/Contect';
import Header from '../src/Components/Header/Header.js';
import Footer from '../src/Components/Footer/Footer.js';
import WebFont from 'webfontloader';
import { useEffect, useState } from 'react';
// import Boutique from '../src/Components/Boutique/Boutique.js';

function App() {
  WebFont.load({
    google: {
      families: ['sans-serif', 'Roboto', 'Roxborough']
    }
  });

  const [isFooter, setIsFooter] = useState(true)

  // console.log(window.location.pathname === '/waitlist')
  // useEffect(() => {
  // console.log('window.location.pathname')
  // if (window.location.pathname !== '/waitlist')
  //   setIsFooter(false)

  // setIsFooter(true)
  // }, [])


  return (
    <BrowserRouter>
      {
        isFooter &&
        <Header />
      }
      <Routes>
        <Route path="/" element={<HomeTabs setIsFooter={setIsFooter} />} />
        <Route path="/home" element={<Home setIsFooter={setIsFooter} />} />
        <Route path="/how-to-use" element={<HowToUsePage setIsFooter={setIsFooter} />} />
        <Route path="/benefits" element={<BenefitsPage setIsFooter={setIsFooter} />} />
        <Route path="/waitlist" element={<WaitListPage setIsFooter={setIsFooter} />} />
      </Routes>
      {
        isFooter &&
        <Footer />
      }

    </BrowserRouter>
  );
}

export default App;
