import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './waitListPage.css'
import { FaArrowUp, FaFacebook, FaInstagram, FaPlus, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { MdOutlineCancel } from "react-icons/md";
import { CiCirclePlus } from "react-icons/ci";
import axios from 'axios'
import { url } from '../../productionvariable.js'
import ProductsSection from './ProductsSection.js'
import ProductsSection2 from './ProductsSection2.js'
import ProductsSection3 from './ProductsSection3.js'
import ProductsSection4 from './ProductsSection4.js'


const WaitListPage = ({ setIsFooter }) => {
  setIsFooter(false)

  // const component = useRef(null)
  // useLayoutEffect(() => {
  //   let ctx = gsap.context(()=>{
  //     const t1 = gsap.timeline();

  //     t1.from("#aniFormBox", {
  //       y:"100",
  //       // position:,
  //       opacity:0,
  //       // y:"-=20",
  //       duration:1,
  //       delay:1
  //     })

  //   }, component)

  //   return () => ctx.revert();
  // }, [])

  const divRef = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline()

    if (window.innerWidth > 750) {
      tl.from(['.slide', '.joinForm'], {
        delay: 0.5,
        opacity: 0,
        y: '-20',
        duration: 0.4,
        stagger: 0.5
      })

      tl.from('.joinBox', {
        opacity: 0,
        x: '60',
        duration: 0.4,
        stagger: 0.5
      })
      tl.from('.joinBtnx', {
        opacity: 0,
        scale: 1.3

      })
    }

  }, []);

  const [isAnimate, setIsAnimate] = useState(false)
  const animate = () => {
    window.scrollTo(0, 0)

    if (window.innerWidth > 750) {

      if (!isAnimate) {
        gsap.to('#aniFormBox', {
          // opacity: 0,
          minWidth: 500,
          // minHeight:600,
          y: '-50%',
          x: (window.innerWidth - 500) / 2,
          duration: 0.5,
          position: 'relative',
          zIndex: 99999
          // delay:1
        });
      }
      else {
        gsap.to('#aniFormBox', {
          minWidth: 0,
          minHeight: 0,
          y: 0,
          x: '00',
          duration: 0.5,
        });
      }
      setIsAnimate(!isAnimate)

    } else {
      gsap.from('#aniFormBox', {
        delay: 0.8,
        scale: 0.8,
        // rotate:2,
        duration: 0.5,
        zIndex: 99999
        // delay:1
      });
      openForm()
    }


  }
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
  }, [])
  useEffect(() => {
    gsap.from('#joinWL1', {
      scale: 0.6,
      opacity: 0,
      duration: 0.4,

      scrollTrigger: {
        trigger: '#joinWL1',
        scroller: 'body',
        start: 'top 70%',
        end: 'bottom 10%',
        // markers: true
      }

    })
    gsap.from('#joinWL2', {
      scale: 0.6,
      opacity: 0,
      duration: 0.3,

      scrollTrigger: {
        trigger: '#joinWL2',
        scroller: 'body',
        start: 'top 70%',
        end: 'bottom 10%',
        // markers: true
      }

    })
  }, [])


  //  backend -----------
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isTrue, setIsTrue] = useState(false);


  const formSubmitHandler = async (e) => {
    e.preventDefault();
    // console.log(name, email, rating, dob)
    const data = {
      // name,
      // email,
      phone
    }
    let isPhone = false

    phone.split('')?.forEach(e => {
      if (e === '0' || e === '1' || e === '2' || e === '3' || e === '4' || e === '5' || e === '6' || e === '7' || e === '8' || e === '9' || e === '+' || e === ' ' || e === 0 || e === 1 || e === 2 || e === 3 || e === 4 || e === 5 || e === 6 || e === 7 || e === 8 || e === 9) {
        // console.log('a',e)
      } else {
        isPhone = true
      }
    });

    if (phone.length <= 9 || phone.length >= 15 || isPhone) {
      alert("Invalid Whatsapp number. enter no between 10 to 13 digit, do not enter any special character or space")
    } else {
      setIsTrue(true)
      const config = { headers: { "Content-Type": "application/json", Accept: 'application/json' } };
      // {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     Accept: 'application/json'
      //   }
      // }

      // console.log(data)
      await axios.post(`${url}/api/v1/waitform`, data, config)
        .then((res) => {
          alert("Email Send SuccussFully. Thankyou for Join Wait List")
          window.location.reload()

        }).catch((error) => {
          setIsTrue(false)
          // console.log(error)

          // const checkDupicate = error.response.data.message.search('duplicate')

          // if (checkDupicate !=== -1) {
          //   alert("This Email is Already Exist. Please Enter new Email")
          // }
          alert(`Technical Error. ${error}`)
        })
    }

  }
  const openForm = () => {
    // const form = document.getElementById('joinFormStickyId')
    // form.style.display = 'Block'
    gsap.to('#joinFormStickyId', {
      display: 'block',
      // transform:scale(0.5),
      scale: 1,

      y: 0,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      duration: 0.8

    })

  }
  const closeForm = () => {
    gsap.to('#joinFormStickyId', {
      duration: 0.8,
      y: '-200%',
      scale: 0,
      // display: 'none',
    })

  }

  return (
    <div className='waitListBox' >
      <div className="sideBar">
        <div className='logoBox'>
          <img src={require('../../images/Logo.avif')} alt="Logo" width={200}/>
        </div>

        <div className="sideBox1">
          <div className='slide'>
            <h4>Current Season:</h4>
            <p>Spring 24</p>
          </div>

          <div className='slide'>
            <h4>Next Release:</h4>
            <p>65D 5H 11M 47S</p>
          </div>

          <div className='slide'>
            <h4>Waiting List:</h4>
            <p>Open</p>
          </div>
        </div>
        <div className="sideBox2" useRef={divRef} >
          <div id='aniFormBox' className="joinFormBox"  >

            <form className='joinForm' onSubmit={formSubmitHandler}>
              <div className='crossBox'>
                <span className='crossBtn' onClick={animate}> {!isAnimate ? <CiCirclePlus size={18} fontWeight={600} color={'white'} /> : <MdOutlineCancel fontWeight={600} size={18} color='white' />}  </span>
              </div>
              <div className='formContent'>
                <p>Pastel 24 by FRAGRENS is launching soon and we’re reserving bottles for our Priority Customers.</p>
                {
                  isAnimate &&
                  <>
                    <p>By joining this waitlist, you automatically enroll in our Priority Customer Program and get access to all our new and upcoming products before anyone else in the entire world.</p>

                    <p>This is a limited program and only early joiners are welcome today.</p>
                  </>
                }

              </div>
              <h4>Join Waiting List:</h4>
              <p>We will communicate over WhatsApp.</p>
              {/* <input type="text" value={name} onChange={e => setName(e.target.value)} className='formInput' required placeholder="Name" /> */}
              <input type="tel" value={phone} onChange={e => setPhone(e.target.value)} className='formInput' required placeholder="What'sapp no" />
              {/* <input type="email" value={email} onChange={e => setEmail(e.target.value)} className='formInput' required placeholder="Email" /> */}
              {
                isTrue ? <p>Processing...</p> :
                  <input type="submit" className='submitBtn' value="Submit" />
              }

            </form>
          </div>
        </div>
      </div>

      <div className="wl-left-box">

        <div className='topImgTag'>
          <div className='topImgTagBg'>

            {
              !isAnimate &&
              <div className='joinBox'>
                <h5>Introducing Fine Fragrances by Fragrens. If at all you do wear one, wear a ‘Bouquet Of Scents’. The waitlist is now open.</h5>
                
                <p>Benefits of joining the waitlist:</p>

                <p>01 Entry into our Priority Customer Program</p>
                <p>02 Get access to our products on priority.</p>
                <p>03 Get special offers and prices.</p>
                <p>04 Elevated complimentary offerings.</p>
                <p>05 Get a Perfume Recommendation Quiz.</p>
                <p>06 A huge shout out on Instagram!</p>
                <p>07 A chance to grace our insider parties. ‘Be invited’.</p>

                <p>
                  Note: Each bottle of Fragrens Perfume is marked at INR 1490, with offer price of INR 990 for our Priority Customers; and comes with a complimentary Pastel ‘24 Discovery Set.
                </p>
                <button className='joinBtnx' onClick={e => { e.preventDefault(); animate() }}>
                  {window.innerWidth < 750 && <FaArrowUp color='gray' size={10} />}  JOIN <span className='pointBtn'></span></button>
              </div>
            }

          </div>

        </div>

        <div className='mobileView'>
          <div>
            <img src="https://thefragrens.com/cdn/shop/files/lavbs_900x.jpg?v=1678877219" alt="" width={'100%'} />
          </div>

          <div className='joinContainer'>
            <div className='joinBox'>
              <h5>Introducing Fine Fragrances by Fragrens. If at all you do wear one, wear a ‘Bouquet Of Scents’. The waitlist is now open.</h5>
              <p>Benefits of joining the waitlist:</p>

              <p>01 Entry into our Priority Customer Program</p>
              <p>02 Get access to our products on priority.</p>
              <p>03 Get special offers and prices.</p>
              <p>04 Elevated complimentary offerings.</p>
              <p>05 Get a Perfume Recommendation Quiz.</p>
              <p>06 A huge shout out on Instagram!</p>
              <p>07 A chance to grace our insider parties. ‘Be invited’.</p>

              <p>
                Note: Each bottle of Fragrens Perfume is marked at INR 1490, with offer price of INR 990 for our Priority Customers; and comes with a complimentary Pastel ‘24 Discovery Set.
              </p>
              <button className='joinBtnx' onClick={e => { e.preventDefault(); animate() }}>
                {window.innerWidth < 750 && <FaArrowUp color='gray' size={10} />}  JOIN <span className='pointBtn'></span></button>
            </div>
          </div>

        </div>

          <div className='videoBox'>
          <video  src={'https://res.cloudinary.com/dvxxcrbmx/video/upload/v1718879673/sanwariya/Video_2024_06_20_15_13_32_t96w2r.mp4'} controls></video>
        </div>


        {/* <div className="productBox">
          <div className="product-item">
            <div className='product-content'>
              <h3>EUCALYPTUS (20 ml)</h3>
              <p>Eucalyptus leaves are collected from every state in India, then left to dry. They are crushed and steam distilled using the age-old process and the purest form of oil is obtained. It can have an enchanting effect in your room, body and mind.</p>
            </div>
            <div className='product-img'>
              <img src={require('../../images/waitlist/p1.webp')} alt="" />
            </div>

          </div>
          <div className="product-item">
            <div className='product-content'>
              <h3>EUCALYPTUS (20 ml)</h3>

              <p>
                Eucalyptus leaves are collected from every state in India, then left to dry. They are crushed and steam distilled using the age-old process and the purest form of oil is obtained. It can have an enchanting effect in your room, body and mind.

              </p>


            </div>
            <div className='product-img'>
              <img src={require('../../images/waitlist/p3.jpg')} alt="" />
            </div>

          </div>


        </div> */}



        <ProductsSection /> <br />
        <ProductsSection2 />  <br />
        <ProductsSection3 />  <br />
        <ProductsSection4 />  <br />

        {/* <div className='joinWL' id='joinWL1'>
          <p>Join Waitlist</p>
          <button className='joinBtnx joinBtnWl' onClick={e => { e.preventDefault(); animate() }}> {window.innerWidth < 750 && <FaArrowUp color='gray' size={10} />} JOIN <span className='pointBtn'></span></button>
        </div> */}

      

        {/* <ReviewSlider /> */}

{/* 
        <div className='shopifyTag'>
          <div className='shopifyTagBg'>

            <div className='shopifyJoinBox'>
              <h5>A new model for perfumery. Natural, limited-edition, blended in the English countryside.</h5>
              <p>01 Join our waiting list</p>

              <button className='shopJoinBtn'>Shopify </button>
            </div>

          </div>

        </div> */}


        <div className='joinWL' id='joinWL2'>
          <p>Join Waitlist</p>
          <button className='joinBtnx joinBtnWl' onClick={e => { e.preventDefault(); animate() }}> {window.innerWidth < 750 && <FaArrowUp color='gray' size={10} />}  JOIN <span className='pointBtn'></span></button>
        </div>

        <div className="footer">

          <div className='footerItem1'>

            {/* <div className='navLinks'>
              <Link>How it works</Link>
              <Link>Returns</Link>
            </div> */}

            <div className='iconLinks'>
              <a href="/"><FaInstagram /></a>
              <a href="/"><FaFacebook /></a>
              <a href="/"><FaTwitter /></a>
              <a href="/"><FaWhatsapp /></a>
            </div>
          </div>
          <div className='footerItem1'>
            {/* <div className='navLinks'>
              <Link>Privacy Policy</Link>
              <Link>Terms and Conditions</Link>
            </div> */}
          </div>

        </div>
      </div>

      {/* <div id='joinFormStickyId'> */}
      <div className="joinFormSticky" id='joinFormStickyId'>
        <div className="joinFormBox "  >

          <form className='joinForm joinFormBoxMV' onSubmit={formSubmitHandler}>
            <div className='crossBox'>
              <span className='crossBtn' onClick={closeForm}> {!isAnimate ? <CiCirclePlus size={18} fontWeight={600} color={'white'} /> : <MdOutlineCancel fontWeight={600} size={18} color='white' />}  </span>
            </div>
            <div className='formContent'>
              <p> Pastel 24 by FRAGRENS is launching soon and we’re reserving bottles for our Priority Customers.</p>
              <p>By joining this waitlist, you automatically enroll in our Priority Customer Program and get access to all our new and upcoming products before anyone else in the entire world.</p>
              <p>

                This is a limited program and only early joiners are welcome today.              </p>
            </div>
            <h4>Join Waiting List:</h4>
            <p>We will communicate over WhatsApp.</p>

            {/* <input type="text" value={name} onChange={e => setName(e.target.value)} className='formInput' required placeholder="Name" /> */}
            <input type="tel" value={phone} onChange={e => setPhone(e.target.value)} className='formInput' required placeholder="What'sapp no" />
            {/* <input type="email" value={email} onChange={e => setEmail(e.target.value)} className='formInput' required placeholder="Email" /> */}
            {
              isTrue ? <p>Wait...</p> :
                <input type="submit" className='submitBtn' value="Submit" />
            }

          </form>
        </div>
      </div>
      {/* </div> */}

      <div className='joinSticky'>
        <button onClick={openForm}>+ Join Waitlist</button>
      </div>
    </div>
  )
}

export default WaitListPage