import React from 'react'
import './homeTabs.css'
import { FaArrowRight, FaTree, FaUser } from 'react-icons/fa'
import { MdOutlineLocalFlorist, MdShop, MdStop, MdStore } from 'react-icons/md'
import { IoMdSettings } from "react-icons/io";
import { TbBrandComedyCentral } from "react-icons/tb";
import { Link } from 'react-router-dom';

const HomeTabs = ({ setIsFooter }) => {
    setIsFooter(true)

    return (
        <div>
            <div className='white-hide'></div>
            <div className='space'></div>
            <div className='tabsContainer'>
                <div className="tabsBox">
                <Link to={'https://thefragrens.com/'} className='b5' target='_blank'>
                        <div className="tabs">
                            <MdStore size={60} />
                            Store
                            <FaArrowRight className='arrow' />

                        </div>
                    </Link>
                    <Link to={'https://boutique.thefragrens.com'} className='b4' target='_blank'>
                        <div className="tabs">
                            <MdShop size={60} />
                            Boutique
                            <FaArrowRight className='arrow' />

                        </div>
                    </Link>
                    <Link to={'https://www.banwari.org/'} className='b3' target='_blank'>
                        <div className="tabs">
                            <FaTree size={60} />
                            Legacy
                            <FaArrowRight className='arrow' />
                        </div>
                    </Link>


                    <Link to={'/waitlist'} className='b2' target='_blank'>
                        <div className="tabs">
                            <TbBrandComedyCentral size={60} />
                            Wait List
                            <FaArrowRight className='arrow' />
                        </div>
                    </Link>

                    
                    <Link to={'/home'} className='b1' target='_blank'>
                        <div className="tabs">
                            <MdOutlineLocalFlorist size={60} />
                            QR
                            <FaArrowRight className='arrow' />
                        </div>
                    </Link>

                   

                  

                   
                </div>

            </div>
        </div>
    )
}

export default HomeTabs