import React from 'react'
import './benefitsPage.css'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

const BenefitsPage = ({setIsFooter}) => {
setIsFooter(true)
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className='BenContainer'>
      <div className="productsBox">
        <h2>Products</h2>
        <a href={"/benefits#JASMINE"}>JASMINE</a>
        <a href={"/benefits#Teatree"}>Teatree</a>
        <a href={"/benefits#Ylang Ylang"}>Ylang Ylang</a>
        <a href={"/benefits#Orange"}>Orange</a>
        <a href={"/benefits#Basil"}>Basil</a>
        <a href={"/benefits#Cedarwood"}>Cedarwood</a>
        <a href={"/benefits#Clove"}>Clove</a>
        <a href={"/benefits#Eucalyptus"}>Eucalyptus</a>
        <a href={"/benefits#Frankincense"}>Frankincense</a>

      </div>
      <div className='benefitsContainer'>

        <h1>Benefits</h1>

        <div className="topicalAppBox">
          <div className='contentBox'>
            <h1>JASMINE</h1>
            <p>FRAGRENS Jasmine pure essential oil offers a range of potential benefits due to its chemical composition and aromatic properties. Here are some common benefits associated with jasmine essential oil:
            </p>
            <ul>
              <li>Relaxation and Stress Relief: Jasmine oil is known for its calming and soothing properties. In aromatherapy, it is often used to promote relaxation, reduce anxiety, and alleviate stress. The fragrance of jasmine oil can help uplift mood and promote a sense of well-being.
              </li>
              <li>
                Skin Care: Jasmine oil has moisturizing and nourishing properties that can benefit the skin. It is often used in skincare products to help improve skin elasticity, reduce the appearance of scars and stretch marks, and balance oily or dry skin. Jasmine oil is also known for its anti-inflammatory properties, which can help soothe irritated skin.

              </li>
              <li>Aphrodisiac Effects: Jasmine has a long-standing reputation as an aphrodisiac. The aromatic scent of jasmine oil is believed to have a positive impact on mood and can help enhance romantic feelings and intimacy.
              </li>
              <li>Antidepressant Effects: The aroma of jasmine oil is believed to have antidepressant effects and can help uplift mood. It is often used in aromatherapy to alleviate symptoms of depression and promote a sense of joy and optimism.
              </li>
              <li>Antiseptic and Antiviral Properties: Jasmine essential oil has natural antiseptic properties, which can help prevent infections and protect wounds from microbial growth. It also possesses antiviral properties that may assist in combating certain viruses.
              </li>
              <li>Hormonal Balance: Jasmine oil is known to have hormonal balancing properties and may be beneficial for regulating menstrual cycles and easing menstrual cramps. It can also help alleviate symptoms of menopause, such as hot flashes and mood swings.
              </li>
              <li>It's important to note that while jasmine essential oil has potential benefits, individual experiences may vary, and it's always advisable to consult with a qualified aromatherapist or healthcare professional before using essential oils for specific therapeutic purposes.
              </li>
            </ul>
          </div>
          <div className='imgBox'>
            <img src={require('../../images/bnf/rose1.jpeg')} alt="s" />

          </div>
        </div>

        <div className="topicalAppBox" id='Teatree'>

          <div className='imgBox'>
                       <img src={require('../../images/bnf/lemongrass1.jpeg')} alt="s" />

          </div>

          <div className='contentBox'>
            <h1>Teatree</h1>
            <p>Tea tree essential oil is renowned for its numerous beneficial properties and has been used for centuries for its therapeutic effects. Here are some of the key benefits associated with tea tree essential oil:
            </p>
            <ul>
              <li>Antimicrobial and Antiseptic Properties: Tea tree oil is known for its powerful antimicrobial properties. It can help kill or inhibit the growth of bacteria, fungi, and viruses. It is commonly used topically to cleanse and disinfect wounds, cuts, and skin infections. It may also be effective against acne-causing bacteria and can be used as a natural remedy for acne.</li>
              <li>
                Skin Care: Tea tree oil is widely used in skincare products due to its ability to soothe and rejuvenate the skin. It can help alleviate skin conditions such as eczema, psoriasis, and dermatitis. Its anti-inflammatory properties can reduce redness, swelling, and itching. Additionally, tea tree oil can assist in balancing oily skin and unclogging pores.
              </li>
              <li>Scalp and Hair Health: Tea tree oil is beneficial for maintaining a healthy scalp and promoting hair growth. It can help control dandruff, scalp itchiness, and dryness. When added to shampoos or used as a scalp treatment, it may help remove excess oil and debris, reduce scalp inflammation, and improve overall hair and scalp health.
              </li>
              <li>Natural Insect Repellent: The strong scent of tea tree oil is known to repel insects such as mosquitoes, ticks, and lice. It can be used as a natural alternative to conventional insect repellents, particularly for those seeking chemical-free options.

              </li>
              <li>Respiratory Support: Inhalation of tea tree oil vapor or its use in steam inhalation can provide respiratory benefits. It may help alleviate symptoms of congestion, coughs, and sinusitis. Its expectorant properties can assist in loosening mucus and clearing the airways.</li>
              <li>Nail Care: Tea tree oil is commonly used to support healthy nails and combat fungal nail infections (onychomycosis). Its antifungal properties help inhibit the growth of fungi that cause nail infections.
              </li>
            </ul>
            <br />
            <p>It's important to note that tea tree oil should be used with caution and diluted properly before applying it to the skin or using it internally. Some individuals may experience skin irritation or allergic reactions, so a patch test is recommended before widespread use. It's advisable to consult a healthcare professional or aromatherapist for guidance on safe and appropriate usage.
            </p>
          </div>
        </div>


        <div className="topicalAppBox" id='Ylang Ylang'>


          <div className='contentBox'>
            <h1>Ylang Ylang</h1>
            <p>Ylang Ylang essential oil offers a range of potential benefits due to its aromatic and therapeutic properties. Here are some of the benefits associated with Ylang Ylang essential oil:
            </p>
            <ul>
              <li>Relaxation and Stress Relief: Ylang Ylang oil is widely used for its calming and relaxing effects. It can help reduce stress, anxiety, and nervous tension. The oil is believed to have sedative properties that promote a sense of tranquility and emotional well-being.
              </li>
              <li>Mood Enhancement: Ylang Ylang oil is known for its uplifting and mood-enhancing properties. It can help alleviate feelings of sadness, frustration, or irritability. The sweet floral scent of Ylang Ylang is often used to create a positive and uplifting atmosphere.</li>
              <li>Aphrodisiac Effects: Ylang Ylang oil has a reputation as an aphrodisiac and is used to enhance romantic moods. It is believed to help increase libido and improve overall sexual well-being. The sensual fragrance of Ylang Ylang is often used in perfumes and massage oils.
              </li>
              <li>Skincare Benefits: Ylang Ylang essential oil is used in skincare products due to its balancing and moisturizing properties. It can help regulate sebum production, making it suitable for both oily and dry skin. Ylang Ylang oil is also believed to have soothing effects on the skin and may help reduce the appearance of blemishes.
              </li>
              <li>Hair and Scalp Health: Ylang Ylang oil is beneficial for promoting healthy hair and scalp. It is used in hair care products to help nourish the hair, stimulate hair growth, and add shine. The oil's moisturizing properties can also help soothe dry scalp and reduce dandruff.
              </li>
              <li>Natural Perfume: Ylang Ylang essential oil has a delightful floral fragrance that makes it a popular ingredient in perfumes and fragrances. Its exotic and sensual scent can uplift the mood and provide a natural and appealing aroma.
              </li>
              <li>Aromatherapy Benefits: Ylang Ylang oil is widely used in aromatherapy due to its relaxing and mood-enhancing effects. It can be diffused in a room to create a calming and soothing atmosphere. Inhalation of the oil's aroma is believed to have a positive impact on emotional well-being.
              </li>
            </ul>
            <br />
            <p>It's important to note that individual experiences may vary, and the use of Ylang Ylang essential oil should be done with proper dilution and consideration of personal sensitivities. It is recommended to consult a qualified aromatherapist or healthcare professional before using essential oils for therapeutic purposes.
            </p>
          </div>


          <div className='imgBox'>
            <img src={require('../../images/bnf/ylang.jpeg')} alt="s" />

          </div>
        </div>

        <div className="topicalAppBox" id='Orange'>

          <div className='imgBox'>
                       <img src={require('../../images/bnf/jojoba.jpeg')} alt="s" />

          </div>

          <div className='contentBox'>
            <h1>Orange</h1>
            <p>Orange essential oil offers a variety of potential benefits due to its aromatic and therapeutic properties. Here are some of the benefits associated with orange essential oil:

            </p>
            <ul>
              <li>Uplifting and Mood-Boosting: The fresh, citrusy aroma of orange essential oil is known for its uplifting and mood-enhancing effects. Inhaling the scent can help improve mood, reduce stress, and alleviate feelings of anxiety or depression.
              </li>
              <li>Energizing and Invigorating: Orange oil is often used to increase energy and promote a sense of vitality. Its aroma can provide a natural pick-me-up, making it beneficial for combating fatigue and boosting overall alertness.
              </li>

              <li>Relaxation and Stress Relief: While orange oil is known for its energizing properties, it can also help induce relaxation and relieve stress. Its calming scent can help soothe the mind and promote a sense of calmness and tranquility.
              </li>

              <li>Digestive Support: Orange essential oil is believed to have digestive benefits. It can help stimulate digestion, reduce symptoms of indigestion, and alleviate bloating or discomfort. Some people use orange oil in massage blends or add it to warm water for digestive support.</li>

              <li>Skin Health: Orange oil is used in skincare products due to its astringent and antibacterial properties. It can help balance oily skin, reduce the appearance of blemishes, and promote a healthy complexion. However, it's important to note that orange oil is photosensitive, meaning it can increase skin sensitivity to sunlight, so caution should be exercised when using it topically.
              </li>

              <li>Natural Cleaning and Air Freshening: The antimicrobial properties of orange essential oil make it a useful ingredient for natural cleaning products. It can be added to homemade cleaners to help sanitize surfaces and eliminate odors. Orange oil is also commonly used to freshen the air in a room, either through diffusion or as an ingredient in homemade air fresheners.
              </li>

              <li>Immune Support: Orange oil is rich in antioxidants and vitamin C, which can help support the immune system. It may assist in boosting the body's natural defenses and promoting overall well-being.
              </li>


            </ul>
            <br />
            <p>It's important to note that individual experiences may vary, and the use of orange essential oil should be done with proper dilution and consideration of personal sensitivities. It is recommended to consult a qualified aromatherapist or healthcare professional before using essential oils for therapeutic purposes.

            </p>
          </div>
        </div>

        <div className="topicalAppBox" id='Basil'>


          <div className='contentBox'>
            <h1>Basil</h1>
            <p>Basil pure essential oil offers several potential benefits due to its unique properties. Here are some of the potential benefits associated with basil essential oil:

            </p>
            <ul>
              <li>Uplifting and Energizing: Basil oil has a refreshing and invigorating aroma that can uplift the mood and promote mental alertness. It is often used to help reduce feelings of fatigue, promote concentration, and enhance focus.
              </li>
              <li>Stress Relief: Basil oil is known for its calming and soothing effects on the mind and body. It can help reduce feelings of stress, anxiety, and tension. Diffusing basil oil or using it in a massage blend may help create a sense of relaxation and promote a more balanced state of mind.
              </li>
              <li>Respiratory Support: Basil oil possesses expectorant properties that may help ease respiratory congestion and promote clear breathing. It can be useful in providing relief from coughs, colds, sinus congestion, and respiratory discomfort.
              </li>
              <li>Muscle Relaxation: Basil oil has analgesic and muscle-relaxing properties, making it beneficial for soothing sore muscles, reducing muscle tension, and relieving minor muscular discomfort. Massaging diluted basil oil onto affected areas may help alleviate muscle stiffness and promote relaxation.
              </li>
              <li>Digestive Aid: Basil oil may aid digestion by stimulating the digestive system and promoting healthy digestion. It can help relieve digestive discomfort, gas, bloating, and indigestion. Diluted basil oil can be massaged onto the abdomen or used in a compress to support digestive health.
              </li>
              <li>Skin Care: Basil oil has antimicrobial properties that may help combat skin blemishes, minor skin irritations, and infections. It can be used in skincare products to promote clear, healthy-looking skin. However, it is important to properly dilute basil oil before applying it to the skin and perform a patch test to check for sensitivity.
              </li>
            </ul>
            <br />
            <p>As with any essential oil, it is essential to use basil essential oil properly and in appropriate dilution. Pregnant women, nursing mothers, and individuals with specific health conditions should consult with a healthcare professional before using basil oil.
            </p>
          </div>


          <div className='imgBox'>
                       <img src={require('../../images/bnf/rose1.jpeg')} alt="s" />

          </div>
        </div>


        <div className="topicalAppBox" id='Cedarwood'>

          <div className='imgBox'>
                       <img src={require('../../images/bnf/basil.jpeg')} alt="s" />

          </div>

          <div className='contentBox'>
            <h1>Cedarwood</h1>
            <p>Cedarwood essential oil offers various potential benefits due to its unique properties. Here are some of the potential benefits associated with cedarwood essential oil:

            </p>
            <ul>
              <li>Relaxation and Calming: Cedarwood oil is known for its calming and soothing effects. It can help promote relaxation, reduce feelings of stress and anxiety, and create a peaceful atmosphere.
              </li>
              <li>Grounding and Emotional Balance: The warm, woody aroma of cedarwood oil is often used to provide a sense of grounding and emotional stability. It can help balance emotions, enhance mental clarity, and promote a sense of inner strength.
              </li>
              <li>
                Sleep Support: Cedarwood oil is commonly used to support healthy sleep patterns. Its calming properties can help relax the mind and body, creating an environment conducive to a restful night's sleep.

              </li>

              <li>Respiratory Health: Cedarwood oil has properties that may support respiratory health. It can help clear congestion, soothe coughs, and promote easier breathing. Inhalation of cedarwood oil vapor or using it in a steam inhalation may offer respiratory relief.
              </li>
              <li>Skin and Hair Care: Cedarwood oil is used in skincare and hair care products for its potential benefits. It may help improve the appearance of skin blemishes, support a clear complexion, and promote a healthy scalp. It can also be used to help reduce the appearance of skin imperfections.
              </li>
              <li>Insect Repellent: Cedarwood oil has natural insect-repellent properties. It can be used to help repel mosquitoes, flies, and other insects. Adding cedarwood oil to natural insect repellent blends or using it in diffusers can help create a bug-free environment.
              </li>
              <li>Natural Deodorizer: The pleasant aroma of cedarwood oil makes it a popular choice as a natural deodorizer. It can help neutralize odors and freshen up spaces. Cedarwood oil is often used in DIY cleaning products, room sprays, and air fresheners.
              </li>
            </ul>
            <br />
            <p>As with any essential oil, it's important to use cedarwood oil properly and in appropriate dilution. Pregnant women, nursing mothers, and individuals with specific health conditions should consult with a healthcare professional before using cedarwood oil.


            </p>
          </div>
        </div>

        <div className="topicalAppBox" id='Clove'>


          <div className='contentBox'>
            <h1>Clove</h1>
            <p>Clove essential oil offers a range of potential benefits due to its unique properties. Here are some of the potential benefits associated with clove essential oil:


            </p>
            <ul>
              <li>Dental Care: Clove oil is commonly used in dental care for its analgesic and antimicrobial properties. It can help alleviate toothache, reduce gum inflammation, and freshen breath.
              </li>
              <li>Pain Relief: Clove oil possesses analgesic properties and can be used topically to provide temporary relief from muscle and joint pain, headaches, and minor discomforts.
              </li>
              <li>Antimicrobial Action: Clove oil has strong antimicrobial properties and can help inhibit the growth of bacteria, fungi, and viruses. It is often used to support a healthy immune system and to disinfect surfaces.
              </li>
              <li>Digestive Support: Clove oil is known to aid digestion by promoting the secretion of digestive enzymes. It can help relieve indigestion, bloating, and stomach discomfort.
              </li>
              <li>Respiratory Health: The warming properties of clove oil can provide respiratory support. It may help relieve coughs, congestion, and bronchial conditions.
              </li>
              <li>Skin Health: Clove oil has antimicrobial and anti-inflammatory properties that can be beneficial for skin health. It may help reduce acne, soothe skin irritations, and support wound healing.</li>
              <li>Mental Well-being: Clove oil has a warm and comforting aroma that can help uplift the mood and relieve stress. It is often used in aromatherapy to promote relaxation and create a soothing atmosphere.
              </li>
            </ul>
            <br />
            <p>It is important to note that clove oil is potent and should be used with caution. It is highly concentrated and should be properly diluted before topical application. Pregnant women, nursing mothers, and individuals with specific health conditions should consult with a healthcare professional before using clove oil.</p>
          </div>


          <div className='imgBox'>
                       <img src={require('../../images/bnf/clove.jpeg')} alt="s" />

          </div>
        </div>

        <div className="topicalAppBox" id='Eucalyptus'>

          <div className='imgBox'>
                       <img src={require('../../images/bnf/grapes.jpeg')} alt="s" />

          </div>

          <div className='contentBox'>
            <h1>Eucalyptus</h1>
            <p>Eucalyptus essential oil offers a wide range of potential benefits due to its unique properties. Here are some of the potential benefits associated with eucalyptus essential oil:


            </p>
            <ul>
              <li>Respiratory Health: Eucalyptus oil is well-known for its ability to support respiratory health. It can help relieve congestion, coughs, sinusitis, and respiratory infections. Inhaling eucalyptus oil vapor or using it in steam inhalation can help open up the airways and promote easier breathing.</li>
              <li>Immune Support: Eucalyptus oil has antimicrobial and immune-stimulating properties. It can help boost the immune system's response to fight off infections and protect against harmful bacteria and viruses.</li>
              <li>Pain Relief: Eucalyptus oil possesses analgesic and anti-inflammatory properties. It can help alleviate muscle and joint pain, headaches, and reduce inflammation associated with conditions like arthritis and sprains.
              </li>
              <li>Mental Clarity and Alertness: The invigorating aroma of eucalyptus oil can help promote mental clarity, focus, and alertness. It is often used in aromatherapy to enhance concentration and relieve mental fatigue.
              </li>
              <li>Skin Care: Eucalyptus oil has antiseptic and anti-inflammatory properties that can benefit skin health. It may help soothe skin irritations, wounds, insect bites, and promote a clear complexion. Diluted eucalyptus oil can also be used for scalp and hair care.
              </li>
              <li>Natural Insect Repellent: Eucalyptus oil has insect-repellent properties, particularly against mosquitoes and ticks. It can be used in natural insect repellent formulations to protect against insect bites.
              </li>
              <li>Stress Relief: The refreshing aroma of eucalyptus oil can help reduce stress, anxiety, and promote relaxation. It can create a calming atmosphere and help relieve tension.
              </li>
            </ul>
            <br />
            <p>As with any essential oil, it's important to use eucalyptus oil properly and in appropriate dilution. It is recommended to perform a patch test and consult with a healthcare professional before using eucalyptus oil, especially for children, pregnant women, and individuals with specific health conditions.


            </p>
          </div>
        </div>


        <div className="topicalAppBox" id='Frankincense'>


          <div className='contentBox'>
            <h1>Frankincense</h1>
            <p>Frankincense essential oil offers a range of potential benefits due to its unique properties. Here are some of the potential benefits associated with frankincense essential oil:



            </p>
            <ul>
              <li>Relaxation and Stress Relief: Frankincense oil has a calming and grounding aroma that can help promote relaxation, reduce stress, and anxiety. It is often used in aromatherapy to create a soothing atmosphere.
              </li>
              <li>Skin Health: Frankincense oil is known for its potential benefits to the skin. It may help improve the appearance of skin blemishes, scars, and fine lines. It can also promote a more even skin tone and a youthful complexion.
              </li>
              <li>Respiratory Support: Inhalation of frankincense oil vapor or using it in steam inhalation can provide respiratory support. It may help alleviate congestion, coughs, and promote easier breathing.
              </li>
              <li>Immune System Support: Frankincense oil has immune-stimulating properties. It may help support a healthy immune system and protect against common infections.
              </li>
              <li>Joint and Muscle Relief: The anti-inflammatory properties of frankincense oil can help reduce inflammation and alleviate joint and muscle discomfort. It is commonly used for relieving pain associated with arthritis and exercise-induced soreness.
              </li>
              <li>Mood Enhancement: Frankincense oil is believed to have mood-enhancing properties. It can help uplift the mood, promote feelings of positivity and well-being.</li>
              <li>Meditation and Spiritual Practices: Frankincense has been used for centuries in spiritual and meditation practices. Its aroma is often associated with enhancing spiritual connection, clarity, and deepening meditation experiences.
              </li>
            </ul>
            <br />
            <p>It's important to note that individual experiences with essential oils can vary, and frankincense oil may affect individuals differently. It is recommended to use frankincense oil according to proper dilution guidelines and consult with a healthcare professional if you have specific health concerns or conditions.
            </p>
          </div>


          <div className='imgBox'>
                       <img src={require('../../images/bnf/pepper.jpeg')} alt="s" />

          </div>
        </div>
      </div>
    </div>
  )
}

export default BenefitsPage