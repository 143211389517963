import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './ProductSection.css';

// import required modules
import { Navigation, Pagination } from 'swiper/modules';

export default function App() {
    return (
        <div className='p-bg4'>
            <div className='productheading'>
                <h4 className='product4'>Laszlo</h4>
            </div>
            <Swiper
                pagination={{
                    type: 'fraction',
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="productMySwiper"
            >

                
                <SwiperSlide className='productItem'>
                    <div className='product2-img'>
                        <img src={require('../../images/waitlist/p1.webp')} alt="user" />
                    </div>
                    <div className='product1-content'>

                        {/* <img src={require('../../images/waitlist/Google-Logo.png')} alt="google" width={80} /> */}
                        <h3>Laszlo</h3>

                        <p>
                            For the free spirited and wild, the oceans and skies, a fragrant mix of Basil, Nutmeg. Elemi and Sage transports you into the open. All combined in Vetiver. Time to be the captain of your ship.
                        </p>


                    </div>

                </SwiperSlide>

            </Swiper>
        </div>
    );
}
