import React, { useState } from 'react'
import { FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa'
import { BiMenuAltRight } from 'react-icons/bi'

import './Header.css'
import { Link } from 'react-router-dom'
import Modal from '../EventModal/EventModal'
// import { Link } from 'react-router-dom'/

const Header = () => {
  //---------------------
  const downNav = document.getElementsByClassName("downNav");

  const mouseEnter = (num) => {
    downNav[num].classList.remove("downNavHide");
  };
  const mouseLeave = (num) => {
    downNav[num].classList.add("downNavHide");
  };
  //---------------------

  // let isHide = true
  const [isHide, setisHide] = useState(true);


  const menuHandler = (e) => {
    // e.prevent
    // console.log("true")

    if (isHide) {
      document.getElementsByClassName("menuContainer")[0].classList.remove("hide")
      document.getElementsByClassName("menuContainer")[0].classList.add("show")
      setisHide(false)
    }
    else {
      document.getElementsByClassName("menuContainer")[0].classList.add("hide")
      document.getElementsByClassName("menuContainer")[0].classList.remove("show")

      setisHide(true)
    }
  }
  console.log(window.location.pathname);
  return (
    <div className='HeaderContainerMain'>

      {
        
        window.location.pathname === '/home' &&  <Modal/>
      }
      
      <header>
        <div className="HeaderContainer">
          <div className="logoBox">
            <img src={require("../../images/Logo.avif")} width="150" alt="logo" />
            {/* <h1 className='logoColor'>Fragrens </h1> */}
          </div>
          <div className="linkBox">
            <a rel="noreferrer" target="_blank" href="https://www.thefragrens.com" >STORE</a>
            <a rel="noreferrer" target="_blank" href="https://boutique.thefragrens.com">BOUTIQUE</a>
            <a rel="noreferrer" target="_blank" href="https://www.banwari.org">LEGACY</a>
            <Link to="/how-to-use">How to Use</Link>
            <Link to="/benefits" id="mouseOverCountry" className='aDiv'>
              Benefits

            </Link>
            {/* <a href="/">LEARN </a> */}


          </div>
          <div className="iconBox">
            {/* <a href="/"><FaHome/></a> */}
            <a href="https://instagram.com/thefragrens?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
            <a href="https://www.linkedin.com/showcase/thefragrens/?viewAsMember=true"><FaLinkedin /></a>
            <a rel="noreferrer" target='_blank' href={"https://wa.me/9953179645"}><FaWhatsapp /></a>
            {/* <a href="https://m.facebook.com/thefragrens"><FaFacebook/></a> */}
            {/* <a href="#"></a> */}
            {/* <Link to='/contect'>Contect</Link> */}
          </div>
          <div onClick={() => menuHandler()} className="menu">

            <BiMenuAltRight />
          </div>
        </div>

        <div className="menuContainer hide">
          <div>
            <a href="https://www.thefragrens.com">STORE</a>
            <a href="https://boutique.thefragrens.com">BOUTIQUE</a>
            <a href="https://www.banwari.org">LEGACY</a>
            <Link to="/how-to-use">How to Use</Link>
            <Link to="/benefits">Benefits</Link>

          </div>
          <div className="iconBoxx">

            {/* <a href="/"><FaHome/></a> */}
            <a href="https://instagram.com/thefragrens?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
            <a href="https://www.linkedin.com/showcase/thefragrens/?viewAsMember=true"><FaLinkedin /></a>
            {/* <a href="https://m.facebook.com/thefragrens"><FaFacebook/></a> */}
          </div>
        </div>
      </header>

    </div>
  )
}

export default Header