import React from 'react';

import './EventModal.css'
import { Link } from 'react-router-dom';


const EventModal = ()=> {

  function closeModal() {
    let closeModal = document.getElementsByClassName('modalContainer')
    // closeModal[0].style.display = 'none'
    closeModal[0].classList.add('hide')
  }

  return (
    <div className='modalContainer' >

      <div className='modalbox'>
          {/* <div className="modalHeader">
            <h3>Event </h3>
            <button onClick={closeModal}>Close</button>
          </div> */}
          <div className="modalItem">
            {/* <img src={require('../../images/pop up poster for event .jpeg')} alt="" /> */}
            <h2>Want to know how to use Fragrance Oils?</h2>
            <div>
            <Link to="how-to-use" onClick={closeModal} className='yesBtn'>Yes</Link>
            <button className='noBtn' onClick={closeModal}>No</button>

            </div>
          </div>
      </div>
     
    </div>
  );
}

export default EventModal
